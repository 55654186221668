export function toJson(response: Response) {
  if (response.status === 204) return null;
  return response.json();
}

export function delay<T>(ms: number) {
  return (data: T) => {
    return new Promise<T>((resolve) => {
      setTimeout(function () {
        resolve(data);
      }, ms);
    });
  };
}

export function withTimeout<R>(ms: number, promise: Promise<R>): Promise<R> {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error("timeout exceeded"));
    }, ms);
    promise.then((value) => {
      clearTimeout(timer);
      resolve(value);
    }, reject);
  });
}

export function requireOkUniversal(res: Response) {
  if (!res.ok) throw new Error("request failed");
  return res;
}
