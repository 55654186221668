import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBug } from "@fortawesome/free-solid-svg-icons";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types";
import { WithChildren } from "../../types/reactTypes";

type NotificationTypeName =
  | "primary"
  | "link"
  | "info"
  | "success"
  | "warning"
  | "danger"
  | "internal";
export interface NotificationProps extends WithChildren {
  icon?: IconProp;
  type: NotificationTypeName;
  className?: string;
  marginTop?: string;
}

function Notification(props: NotificationProps) {
  const icon: IconProp =
    props.type === "internal" ? faBug : (props.icon as [IconPrefix, IconName]);
  return (
    <Container
      className={classNames(`notification is-${props.type}`, props.className)}
      marginTop={props.marginTop}
    >
      {icon && <FontAwesomeIcon icon={icon} size={"2x"} />}
      <div>{props.children}</div>
    </Container>
  );
}

const Container = styled.div<{ marginTop: string }>`
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin-top: ${(props) => props.marginTop};
  svg {
    //margin-top: 4px;
    margin-right: 18px;
  }

  &.is-internal {
    background-color: indigo;
    color: white;
  }
`;

export default Notification;
