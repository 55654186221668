import React from "react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Notification, {
  NotificationProps,
} from "~/components/notification/Notification";

interface ErrorNotificationProps extends Omit<NotificationProps, "type"> {
  children: string;
  details?: string;
}

export function ErrorNotification({
  details,
  children,
  ...props
}: ErrorNotificationProps) {
  return (
    <Notification type={"danger"} icon={faExclamationCircle} {...props}>
      <div>{children}</div>
      {details && <small>{details}</small>}
    </Notification>
  );
}
