import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Button, { ButtonProps } from "./Button";

interface IconButtonProps extends ButtonProps {
  icon: IconProp;
  fixedWidth?: boolean;
}

function IconButton({ icon, fixedWidth, children, ...props }: IconButtonProps) {
  return (
    <Button {...props}>
      <span className="icon">
        <FontAwesomeIcon icon={icon} fixedWidth={fixedWidth} />
      </span>
      <span>{children}</span>
    </Button>
  );
}

export const BackButton = (props: Omit<IconButtonProps, "icon">) => (
  <IconButton {...props} icon={backIcon} />
);

export const backIcon = faChevronLeft;

export default IconButton;
