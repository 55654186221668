import memoizeOne from "memoize-one";
import { UserRole } from "./UserRole";

export enum UserPermission {
  isAdmin = "isAdmin",
  canUpdateCategory = "canUpdateCategory",
  canUpdateUserStatus = "canUpdateUserStatus",
  canWriteComment = "canWriteComment",
  canDeleteComment = "canDeleteComment",
  canStartLivestreams = "canStartLivestreams",
  canAccessLivestreams = "canAccessLivestreams",
  canManageLivestreams = "canManageLivestreams",
  canReadStreamStats = "canReadStreamStats",
  canDebugDetection = "canDebugDetection",
  canCutRecording = "canCutRecording",
}

export type UserPermissions = { [key in UserPermission]: boolean };

export const getPermissionsByRole = memoizeOne(_getPermissionsByRole);
function _getPermissionsByRole(
  role: UserRole | null,
  userId?: number
): UserPermissions {
  const isAdmin = role === UserRole.SuperAdmin;
  const any = (...any: UserRole[]) => {
    return role === UserRole.SuperAdmin || any.indexOf(role) !== -1;
  };
  const isCustomer = role === UserRole.Customer;

  const isLivestreamBlacklisted = livestreamBlacklist.indexOf(userId) !== -1;
  const isDetectionBlacklisted = isLivestreamBlacklisted;

  return {
    isAdmin,
    canUpdateCategory: role === UserRole.Customer || isAdmin,
    canUpdateUserStatus: role === UserRole.Customer || isAdmin,
    canWriteComment: role === UserRole.Customer || isAdmin,
    canDeleteComment: role === UserRole.Customer || isAdmin,
    canAccessLivestreams:
      any(UserRole.Customer, UserRole.LogoDetection) &&
      !isLivestreamBlacklisted,
    canStartLivestreams: (isAdmin || userId === 19) && !isLivestreamBlacklisted,
    canReadStreamStats: isCustomer || isAdmin,
    canManageLivestreams: isAdmin,
    canDebugDetection: isAdmin && !isDetectionBlacklisted,
    canCutRecording: isAdmin,
  };
}

const livestreamBlacklist = [12];

export function hasPermission(
  role: UserRole,
  permission: keyof UserPermission
) {
  return getPermissionsByRole(role)[permission];
}
