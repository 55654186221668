import { useMemo } from "react";
import { useQuery } from "react-query";
import { Brand } from "@core/types/domain/Brand";
import { fetchApi, requireOk, toJson } from "../fetchApi";
import { keyBy } from "~/utils/jsUtils";

function fetchBrands(): Promise<Brand[]> {
  return fetchApi("/logos").then(requireOk).then<Brand[]>(toJson);
}

export function useBrands() {
  return useProjectBrands();
}

export function useProjectBrands() {
  const { data } = useQuery("fetchBrands", fetchBrands);
  return useMemo(() => {
    return {
      brands: data,
      brandByName: keyBy(data, (item) => item.name),
    };
  }, [data]);
}
