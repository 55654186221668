import React, { useCallback, useMemo } from "react";
import { LivestreamTypeExternal } from "@core/types/domain/Livestream";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { LivestreamsTable } from "~/screens/app/detection/livestreams/LivestreamsTable";
import { useQueryParam } from "~/utils/hooks/useQueryParam";
import { toInt } from "~/utils/jsUtils";
import { useExternalLivestreams } from "~/screens/external/streams/useExternalStreams";
import { addHours } from "~/utils/dateUtils";
import { ErrorNotification } from "~/components/notification/ErrorNotification";
import { AppRoute } from "~/config/Routes";

interface ExternalLivestreamsViewProps {}

export function ExternalLivestreamsView(props: ExternalLivestreamsViewProps) {
  const projectId = useQueryParam("project", toInt);
  const code = useQueryParam("code");
  const { data: streams, isLoading: loading } = useExternalLivestreams(
    projectId,
    code
  );
  const upcomingStreams = useMemo(() => {
    if (!streams) return;
    const minDate = addHours(new Date(), -12).getTime();
    return streams.filter((stream) => {
      return stream.plannedStart.getTime() > minDate && !stream.end;
    });
  }, [streams]);

  const getJoinLink = useCallback(
    (stream: LivestreamTypeExternal) => {
      return AppRoute.ExternalLivestream.create(stream.id, code, projectId);
    },
    [code, projectId]
  );

  if (loading) return <AppPageSpinner />;
  if (!streams)
    return (
      <ErrorNotification>
        Failed to get the streams. This could be for technical reasons or
        because you do not have the necessary authorizations.
      </ErrorNotification>
    );
  return (
    <LivestreamsTable
      streams={upcomingStreams}
      isExternal
      getJoinLink={getJoinLink}
    />
  );
}
