import { DetectionServer } from "@core/types/detection/DetectionServer";
import { toJson } from "@core/utils/fetchUtils";

export function fetchDetectionServerStatus(
  server: Pick<DetectionServer, "name" | "adminUrl">
) {
  if (!server.adminUrl)
    throw new Error(`unknown admin server endpoint for server ${server.name}`);
  return fetch(`${server.adminUrl}/gpu?json=1`)
    .then(toJson)
    .then<DetectionServerStatus>((data) => {
      const gpus = data?.["gpus"];
      if (!gpus) throw new Error("invalid response");
      if (gpus.length > 1) throw new Error("more than 1 gpu not supported yet");
      return gpus[0];
    });
}

export interface DetectionServerStatus {
  memoryTotal: number;
  memoryFree: number;
  load: number;
}
