export function keyBy<T, K extends string | number | symbol>(
  list: T[],
  getKey: (obj: T) => K
) {
  return list?.reduce((result, item) => {
    const key = getKey(item);
    result[key] = item;
    return result;
  }, {} as Record<K, T>);
}
