import { useMemo } from "react";
import {
  LivestreamType,
  LivestreamTypeExternal,
} from "@core/types/domain/Livestream";
import { TaskInfo } from "@core/types/detection/DetectionTypes";
import { liveStreamTaskId } from "@core/utils/domain/detectionUtils";
import { DetectionServer } from "@core/types/detection/DetectionServer";
import { useActiveDetectionTasks } from "~/services/detection/detectionService";

export function useLivestreamsWithTasks(
  streams: Array<LivestreamType | LivestreamTypeExternal>
) {
  const tasksQuery = useActiveDetectionTasks();
  return useMemo(() => {
    const tasks = tasksQuery.data;
    return {
      ...tasksQuery,
      data: streams?.map((stream) => {
        // task should be null if not found, but undefined if not feteched
        const task = tryFindTask(tasks, stream.id, stream.server.name);
        return {
          stream,
          task,
        };
      }),
    };
  }, [streams, tasksQuery]);
}

export function useLivestreamTask(
  livestreamId: number,
  server?: Pick<DetectionServer, "name">
) {
  const query = useActiveDetectionTasks();
  return useMemo(() => {
    const task = tryFindTask(query.data, livestreamId, server?.name);
    return {
      ...query,
      data: task,
    };
  }, [query, livestreamId, server]);
}

function tryFindTask(
  tasks: TaskInfo[],
  livestreamId: number,
  preferedServer: string
) {
  if (!tasks) return undefined;
  const toFind = liveStreamTaskId.fromStreamId(livestreamId);
  // if (process.env.NODE_ENV === "development") {
  //   return tasks.find((t) => t.id === "try1") || null
  // }
  const matches = tasks.filter((t) => t.id === toFind);
  const target = matches.find((m) => m.server.name === preferedServer);
  return target || matches[0] || null;
}
