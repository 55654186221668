import React from "react";
import { ExternalPage } from "~/layouts/external/ExternalPage";
import MinimalLayout from "~/layouts/blank/MinimalLayout";
import { ExternalLivestreamsView } from "~/screens/external/streams/ExternalLivestreamsView";

export function ExternalLivestreamsScreen() {
  return (
    <ExternalPage>
      <MinimalLayout>
        <ExternalLivestreamsView />
      </MinimalLayout>
    </ExternalPage>
  );
}
