import React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

interface IconProps extends FontAwesomeIconProps {
  onClick?: () => void;
  title?: string;
  disabled?: boolean;
}

function Icon(props: IconProps) {
  return <FontAwesomeIcon {...props} />;
}

export default Icon;
