import { fetchDetectionApi } from "@core/service/detection/fetchDetectionApi";
import { TaskInfo } from "@core/types/detection/DetectionTypes";
import { toJson } from "~/services/fetchApi";

export function fetchDetectionTask(id: string) {
  return fetchDetectionApi(`/task/${id}`)
    .then(toJson)
    .then<TaskInfo>((json) => {
      return parseTask(id, json?.[id]);
    });
}

export function parseTask(id: string, json: any): TaskInfo {
  return (
    json && {
      id,
      status: json.status.status,
      statusText: json.status?.message,
      webrtcUrl: json.return.webrtc_url,
      request: json.request,
    }
  );
}
