import { useMemo } from "react";
import { useQuery } from "react-query";
import { fetchDetectionTask } from "@core/service/detection/fetchDetectionTask";
import { fetchDetectionTasks } from "@core/service/detection/fetchDetectionTasks";
import { TaskInfo } from "@core/types/detection/DetectionTypes";
import { liveStreamTaskId } from "@core/utils/domain/detectionUtils";
import { DetectionServer } from "@core/types/detection/DetectionServer";
import {
  DetectionServerStatus,
  fetchDetectionServerStatus,
} from "@core/service/detection/fetchDetectionServerStatus";
import { UseQueryOptions } from "react-query/types/react/types";
import { fetchApi, requireOk } from "../fetchApi";
import { strToHash } from "~/utils/jsUtils";
import { queryClient } from "~/utils/fetch/ReactQueryProvider";

export function useTaskInfo(id: string) {
  return useQuery<TaskInfo>(
    ["fetchTaskInfo", id],
    () => fetchDetectionTask(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function refetchActiveDetectionTasks() {
  queryClient.invalidateQueries("useActiveDetectionTasks");
}

export function useActiveDetectionTasks() {
  const query = useQuery<TaskInfo[]>(
    "useActiveDetectionTasks",
    fetchDetectionTasks,
    {
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 15,
      refetchIntervalInBackground: false,
      staleTime: 0,
    }
  );
  return useMemo(() => {
    const filtered = query.data?.filter((task) => {
      return (
        (task.status === "init" || task.status === "working") &&
        liveStreamTaskId.toStreamId(task.id)
      );
    });
    return {
      ...query,
      data: filtered,
    };
  }, [query]);
}

export function taskUrlToId(url: string) {
  const hash = strToHash(url).toString();
  return hash.replace(/-/, "N");
}

export function fetchQueueVideoDetection(id: number) {
  return fetchApi(`/video/${id}/detection/queue`, {
    method: "POST",
  }).then(requireOk);
}

export function useDetectionServerStatus(
  server: DetectionServer,
  options: UseQueryOptions<DetectionServerStatus>
) {
  return useQuery<DetectionServerStatus>(
    ["useDetectionServerStatus", server.id],
    () => fetchDetectionServerStatus(server),
    options
  );
}
